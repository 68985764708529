import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Box, Drawer, Hidden, List, makeStyles, Divider} from '@material-ui/core';
import propTypes from 'helpers/propTypes';
import {NavTopic, Progress} from './components';
import { modules } from '../../../../../docs/';

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 350,
  },
  desktopDrawer: {
    width: 350,
    top: 64,
    bottom: 64,
    height: 'calc(100% - 64px)',
    [theme.breakpoints.up('lg')]: {
      width: 350,
    },
  },
  module: {
    padding: 0,
  },
}));

const Navbar = ({onMobileClose, openMobile}) => {
  const classes = useStyles();

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box mt={5} ml={4} mr={5} mb={0} fontSize={20} fontWeight={900}>
        The Studio Collective
      </Box>
      <Box mt={1} ml={4} mr={5} mb={5} fontSize={14} fontWeight={300}>
        based in San Francisco, CA
      </Box>
      <Divider mb={0} />
      <List className={clsx(classes.module)}>
        {modules.map(topic => (
          <NavTopic topic={topic} key={topic.Id} />
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          classes={{paper: classes.mobileDrawer}}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer anchor="left" classes={{paper: classes.desktopDrawer}} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

Navbar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  course: propTypes.course,
};

Navbar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default Navbar;
