import PropTypes from 'prop-types';

const page = PropTypes.shape({
  title: PropTypes.string.isRequired,
  contentType: PropTypes.string,
  body: PropTypes.string,
  path: PropTypes.string.isRequired,
  dbId: PropTypes.string.isRequired,
});

const topic = PropTypes.shape({
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  path: PropTypes.string,
  dbId: PropTypes.string.isRequired,
});

const course = PropTypes.shape({
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  path: PropTypes.string.isRequired,
  repo: PropTypes.string,
  dbId: PropTypes.string.isRequired,
  directoryName: PropTypes.string,
});

const tutorial = PropTypes.shape({
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
});

const tutorialSection = PropTypes.shape({
  htmlContent: PropTypes.string,
  title: PropTypes.string,
});

const tutorialPage = PropTypes.shape({
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  sections: PropTypes.shape({
    nodes: PropTypes.arrayOf(tutorialSection),
  }),
  previous: tutorial,
  next: tutorial,
});

const track = PropTypes.shape({
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  tutorials: PropTypes.shape({
    nodes: PropTypes.arrayOf(tutorial),
  }),
});

const propTypes = {
  page,
  topic,
  course,
  tutorial,
  tutorialPage,
  track,
};

export default propTypes;
