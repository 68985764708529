import React from 'react';
import clsx from 'clsx';
import {Typography, Box, ListItem, makeStyles} from '@material-ui/core';
import {Link} from 'gatsby-theme-material-ui';
import Emoji from 'a11y-react-emoji';
import {usePageContext} from 'data/page-context';
import propTypes from 'helpers/propTypes';

const contentTypeIcons = {
  Exercise: <Emoji label="Coding Exercise" symbol="💻" />,
  VideoContent: <Emoji label="Video" symbol="📺" />,
  TextContent: <Emoji label="Text" symbol="📚" />,
  Project: <Emoji label="Project" symbol="🛠" />,
  HTMLSlides: <Emoji label="Slides" symbol="📽" />,
  GoogleSlides: <Emoji label="Slides" symbol="📽" />,
  Signup: <Emoji label="Signup" symbol="📋" />,
  CheckForUnderstanding: <Emoji label="Check for Understanding" symbol="🚦" />,
  Assessment: <Emoji label="Assessment" symbol="📝" />,
};

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    textTransform: 'none',
    width: '100%',
    color: theme.palette.text.primary,
    '&:hover': {
      background: theme.palette.alternate.contrast,
      textDecoration: 'none',
    },
    whiteSpace: 'nowrap',
    paddingLeft: theme.spacing(4),
  },
  itemTitle: {
    fontSize: '16px',
  },
  itemHidden: {
    display: 'none',
  },
  itemFinished: {
    color: theme.palette.text.secondary,
    '&:before': {
      content: "'✔'",
      color: theme.palette.text.secondary,
      left: theme.spacing(1.75),
      top: theme.spacing(0.75),
      position: 'absolute',
    },
  },
  itemActive: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '& span': {
      fontWeight: 900,
    },
    '&:before': {
      content: "'➔'",
      color: theme.palette.text.primary,
      left: theme.spacing(1.75),
      top: theme.spacing(0.95),
      position: 'absolute',
    },
  },
}));

const NavItem = ({page, finished, expanded, ...rest}) => {
  const classes = useStyles();
  const pageContext = usePageContext();
  const active = page.path === pageContext.page?.path;

  const pageIcon = contentTypeIcons[page.contentType];

  return (
    <ListItem
      className={clsx({
        [classes.item]: true,
        [classes.itemActive]: active,
        [classes.itemHidden]: !expanded,
        [classes.itemFinished]: finished,
      })}
      {...rest}
    >
      <Link to={page.path} color="inherit" aria-label={page.title}>
        <Box component="span">
          <Box fontSize={14} component="span" px={1}>
            {pageIcon}
          </Box>
          <Typography variant="body2" component="span" className={classes.itemTitle}>
            {page.title}
          </Typography>
        </Box>
      </Link>
    </ListItem>
  );
};

NavItem.propTypes = {
  page: propTypes.page,
};

export default NavItem;
