import React, {useState} from 'react';
import clsx from 'clsx';
import {Box, Typography, List, ListItem, makeStyles, Divider} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import propTypes from 'helpers/propTypes';
import {usePageVisits} from 'data/queries/pages';
import {usePageContext} from 'data/page-context';
import NavItem from '../NavItem';

const useStyles = makeStyles(theme => ({
  pageList: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  navListItem: {
    paddingBottom: 0,
  },
  navGroupContainer: {
    width: '100%',
  },
  navGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  topic: {
    fontSize: '20px',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    '&:before': {
      content: "'▶'",
      left: theme.spacing(2.25),
      top: theme.spacing(4),
      position: 'absolute',
      fontSize: 12,
    },
  },
  topicCompleted: {
    color: theme.palette.text.secondary,
  },
  topicOpen: {
    fontWeight: 900,
    '&:before': {
      transform: 'rotate(90deg)',
    },
  },
  topicActive: {
    color: theme.palette.primary.main,
  },
  completion: {
    color: theme.palette.text.secondary,
  },
}));

const getComponentId = () => {
  const path = window.location.pathname;
  return path.substr(path.lastIndexOf('/')+1);
}


const NavTopic = ({topic}) => {
  const classes = useStyles();
  const theme = useTheme();

  const {pages, id} = topic;

  const pageContext = usePageContext();
  const isCurrentTopic = getComponentId() === id;
  const [openTopic, setOpenTopic] = useState(isCurrentTopic);

  return (
    <ListItem disableGutters className={classes.navListItem}>
      <div key={topic.id} className={classes.navGroupContainer}>
        <Box
          pt={2}
          pb={1}
          fontSize={16}
          bgcolor={theme.palette.background.paper}
          className={classes.navGroup}
        >
          <Box pl={5} pr={1}>
            <Typography
              variant="h6"
              color="textPrimary"
              component="a"
              className={clsx({
                [classes.topic]: true,
                [classes.topicOpen]: openTopic,
                [classes.topicActive]: isCurrentTopic,
              })}
              onClick={() => {
                setOpenTopic(!openTopic);
              }}
            >
              {topic.title}
            </Typography>
          </Box>
          <Box px={1} width="100%">
            <List className={classes.pageList}>
              {pages.map(page => (
                <NavItem
                  page={page}
                  expanded={openTopic}
                  key={page.path}
                />
              ))}
            </List>
          </Box>
        </Box>
        <Divider />
      </div>
    </ListItem>
  );
};

NavTopic.propTypes = {
  topic: propTypes.topic.isRequired,
};

export default NavTopic;
