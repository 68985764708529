export const modules = [
  {
    title: 'Showcases',
    slug: '/showcases'
  },
  {
    title: "Artist Talks",
    slug: '/talks'
  },
  // {
  //   title: 'The Collective',
  //   slug: '/collective'
  // },
  {
    title: 'Introspect',
    slug: '/introspect'
  },
  {
    title: 'Tōsh',
    slug: '/tosh'
  },
  

  // {
  //   title: 'Photos',
  //   slug: '/photos'
  // },
  // {
  //   title: 'Ongoing Projects',
  //   slug: '/projects'
  // },
  // {
  //   title: 'Music & Media',
  //   slug: '/music'
  // },
  // {
  //   title: 'Get Involved',
  //   slug: '/get-involved'
  // },
];
