import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Box, Drawer, Hidden, List, makeStyles, Divider} from '@material-ui/core';
import propTypes from 'helpers/propTypes';
import {NavTopic, Progress} from './components';
import { modules } from '../../../../../docs/';

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 250,
    [theme.breakpoints.up('sm')]: {
      width: 350,
    },
  },
  desktopDrawer: {
    width: 350,
    top: 64,
    bottom: 64,
    height: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      width: 240,
    },
  },
  module: {
    padding: 0,
  },
}));

const Menu = ({onMobileClose, openMobile}) => {
  const classes = useStyles();

  const content = (
    <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
      <List className={clsx(classes.module)}>
      <Divider/>
        {modules.map(topic => (
          <NavTopic topic={topic} key={topic.slug} />
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Hidden smUp>
        <Drawer
          anchor="left"
          classes={{paper: classes.mobileDrawer}}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer anchor="left" classes={{paper: classes.desktopDrawer}} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

Menu.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  course: propTypes.course,
};

Menu.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default Menu;
