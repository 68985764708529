import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  List,
  ListItem,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';import {Button, Link} from 'gatsby-theme-material-ui';
import {Image, DarkModeToggler} from 'components/atoms';
import propTypes from 'helpers/propTypes';
import {useTheme} from '@material-ui/core/styles';
import LoginStateCTA from 'components/atoms/LoginStateCTA';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  logoContainer: {
    width: 140,
    height: 35,
    [theme.breakpoints.up('sm')]: {
      width: 400,
      height: 27,
    },
    [theme.breakpoints.up('md')]: {
      width: 400,
      height: 30,
    },
    paddingRight: 15,
  },
  logoIntro: {
    fontWeight: 400,
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  logoFont: {
    fontWeight: 700,
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listItem: {
    paddingRight: 0,
  },
  listItemButton: {
    whiteSpace: 'nowrap',
    borderRadius: 0,
  },
  iconButton: {
    paddingRight: 0,
    paddingLeft: 0,
    marginLeft: 20,
  },
}));

const getSlug = () => {
  const isBrowser = typeof window !== "undefined";
  let path = "/";
  if (isBrowser)
    path = window.location.pathname;

  if (path.charAt(path.length-1) === '/')
    path = path.slice(0, -1);
  return path.substr(path.lastIndexOf('/'));
}

const getPath = () => {
  const isBrowser = typeof window !== "undefined";
  let path = "/";
  if (isBrowser)
    path = window.location.pathname;

  return path;
}


const TopBar = ({className, onMobileNavOpen, themeToggler, themeMode, ...rest}) => {
  const classes = useStyles();

  const theme = useTheme();
  const isTiny = !useMediaQuery('(min-width:415px)', {
    defaultMatches: false,
  });

  const isTosh = getPath().includes("tosh");

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} color="inherit" {...rest}>
      <Toolbar>
        <div className={classes.logoContainer}>
          <Link to="/" color="inherit" >
            <Box class={classes.logoIntro}>
              the{' '}
              <Typography component="span" variant="inherit" color="primary" className={classes.logoFont}>
                Studio Collective
              </Typography>
            </Box>
          </Link>
        </div>
        <Box flexGrow={1} />
        <List disablePadding className={classes.navigationContainer}>
          {/* { !isTiny && !isTosh &&
          <ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
            <Button
              variant="outlined"
              color="primary"
              component="a"
              target="blank"
              className={classes.listItemButton}
              href="https://discord.gg/prvCQR2TRZ"
            >
              Discord
            </Button>
          </ListItem>
          } */}
          <ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              component="a"
              target="blank"
              className={classes.listItemButton}
              href={isTosh ? "https://instagram.com/toshbeats" : "https://instagram.com/studiocollectivesf"}
            >
              Instagram
            </Button>
          </ListItem>
        </List>
        <Hidden smUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
            className={classes.iconButton}
            disableRipple
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default TopBar;
