import React, {createContext, useContext} from 'react';
import {useApolloLoaderContext} from './apollo-loading-context';

const PageContext = createContext();

const PageContextProvider = incoming => {
  const {apolloLoading} = useApolloLoaderContext();
  const {pageContext, children} = incoming;
  return (
    <PageContext.Provider value={{...pageContext, apolloLoading}}>{children}</PageContext.Provider>
  );
};

const usePageContext = () => useContext(PageContext);

export {PageContextProvider, usePageContext};
